import { Pagination } from '@egjs/flicking-plugins';
import Flicking, { ViewportSlot } from '@egjs/react-flicking';
import "@egjs/react-flicking/dist/flicking.css?__remix_sideEffect__";
import "@egjs/flicking-plugins/dist/pagination.css?__remix_sideEffect__";
import { format } from 'date-fns';
import { useRef } from 'react';

import { NewsSource } from './components/feed/source';
import { dummyDevelopmentSlides, dummySlides } from './data/carousel';

export const NewsFeedHighlights = ({
  className = 'max-w-md w-full h-80',
  slides = dummySlides
}) => {
  const ref = useRef<Flicking | null>(null);
  const plugins = [
  new Pagination({
    type: 'bullet'
  })];

  return (
    <Flicking
      align="center"
      circular={true}
      // plugins={plugins}
      horizontal={false}
      autoResize={true}
      panelsPerView={1}
      hideBeforeInit={true}
      className={className}
      ref={ref}>

      {slides.map((slide, index) => {
        return (
          <div key={index} className={`w-full h-full pr-5`}>
            <div className="p-4 h-full w-full flex flex-col">
              <div className="mb-4 flex justify-between items-center">
                <span className=" font-semibold text-primary/80">
                  Newsfeed highlights
                </span>
                <span className="text-sm text-primary/50">
                  {index + 1} of {slides.length}
                </span>
              </div>
              <div className="flex flex-grow flex-col pt-4">
                <NewsSource
                  name={slide.name}
                  createdAt={slide.createdAt}
                  source={slide.source} />

                <div className="rounded-md bg-primary/10 p-4 mt-4 text-xs">
                  {slide.content}
                </div>
              </div>
              <div className="text-right mt-2 text-sm">
                <span className="font-semibold mr-1">{slide.affected}</span>
                affected - Relevant for
                <span className="font-semibold ml-1">{slide.relevant}</span>
              </div>
            </div>
          </div>);

      })}
      <ViewportSlot>
        <div className="flicking-pagination px-2 top-1/3 flex flex-col space-y-2 items-end"></div>
      </ViewportSlot>
    </Flicking>);

};

const DevelopmentRisk = ({
  createdAt,
  topic,
  risk




}: {createdAt: string;topic: string;risk: string;}) => {
  return (
    <div className="flex">
      <div
        className={`w-4 mr-1 rounded-md min-h-full ${
        risk === 'direct' ?
        'bg-brand-red' :
        risk === 'indirect-association' ?
        'bg-brand-orange' :
        'bg-brand-yellow'}`
        }>
      </div>
      <div className="flex flex-col text-sm space-y-1">
        <div className="font-semibold">
          {format(new Date(createdAt), 'LLLL yyyy')}
        </div>
        <div className="">
          {risk === 'direct' ?
          'Client, project or supplier named' :
          risk === 'indirect-association' ?
          'Probable client connection ' :
          'Risk in client operational area'}
        </div>
        <div className="font-semibold">{topic}</div>
      </div>
    </div>);

};

export const DevelopmentHighlights = ({
  className = 'max-w-md w-full h-80',
  slides = dummyDevelopmentSlides
}) => {
  const ref = useRef<Flicking | null>(null);
  const plugins = [
  new Pagination({
    type: 'bullet'
  })];

  return (
    <Flicking
      align="center"
      circular={true}
      // plugins={plugins}
      horizontal={false}
      autoResize={true}
      panelsPerView={1}
      hideBeforeInit={true}
      className={className}
      ref={ref}>

      {slides.map((slide, index) => {
        return (
          <div key={index} className={`w-full h-full pr-5`}>
            <div className="h-full w-full p-4 flex flex-col">
              <div className="mb-4 flex justify-between items-center">
                <span className=" font-semibold text-primary/80">
                  Development highlights
                </span>
                <span className="text-sm text-primary/50">
                  {index + 1} of {slides.length}
                </span>
              </div>
              <div className="flex flex-col flex-grow pt-4">
                <DevelopmentRisk
                  risk={slide.risk}
                  createdAt={slide.timestamp}
                  topic={slide.topic}>
                </DevelopmentRisk>
                <div className="rounded-md bg-primary/10 p-4 mt-4 text-xs">
                  {slide.content}
                </div>
              </div>
            </div>
          </div>);

      })}
      <ViewportSlot>
        <div className="flicking-pagination px-2 top-1/3 flex flex-col space-y-2 items-end"></div>
      </ViewportSlot>
    </Flicking>);

};